
import "./gallery-page.scss";
import { Options, setup, Vue } from "vue-class-component";
import { useMeta } from "vue-meta";
import Page from "@/components/page/page.vue";
import Panel from "@/components/panel/panel.vue";
import Lightgallery from "lightgallery/vue";
import {
  getImageUrlFromContentfulEntity,
  getGalleryBlockFromContentfulEntity,
} from "@/store/utils";
import { GalleryBlock } from "@/store/common.types";
import { FETCH_GALLERY_PAGE } from "@/store/modules/gallery-page/gallery-page.actions";
import { GALLERY_PAGE_ENTRY_ID } from "@/store/api.config";
import { GalleryPageEntity } from "@/store/modules/gallery-page/gallery-page.model";
import { ContentfulEntity } from "@/store/contentful.types";

@Options({
  name: "GalleryPage",
  components: {
    Page,
    Panel,
    Lightgallery,
  },
  data: () => ({
    plugins: [],
  }),
  methods: {
    onLightGalleryInit: () => {
      // console.log("lightGallery: onLightGalleryInit");
    },
    onBeforeSlide: () => {
      // console.log("lightGallery: onBeforeSlide");
    },
  },
})
export default class GalleryPage extends Vue {
  //#region Meta
  meta = setup(() =>
    useMeta({
      title: "Our Work",
      htmlAttrs: { lang: "en", amp: true },
    })
  );
  //#endregion

  //#region Component
  isLoading = true;
  galleryPageEntity: GalleryPageEntity | null = null;
  galleryBlocks: Array<GalleryBlock> | null = null;
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    await this.$store.dispatch(FETCH_GALLERY_PAGE, GALLERY_PAGE_ENTRY_ID);
    this.galleryPageEntity = this.$store.getters.galleryPageEntity;

    this.galleryBlocks = [];
    this.galleryPageEntity.galleryPageGalleryBlocks.forEach(
      async (entityBlock) => {
        await getGalleryBlockFromContentfulEntity(entityBlock).then(
          (galleryBlock) => this.galleryBlocks.push(galleryBlock)
        );
      }
    );

    this.isLoading = false;
  }
  //#endregion

  //#region Functions
  getGalleryImageUrl(contentfulEntity: ContentfulEntity): string {
    return getImageUrlFromContentfulEntity(contentfulEntity);
  }
  //#endregion
}
GalleryPage.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave",
]);
