<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | Spartan Energy` : `Spartan Energy`
    }}</template>
  </metainfo>
  <div v-if="!this.isLoading" class="gallery-page">
    <Page :hasSplash="false">
      <template #pageContent>
        <Panel
          v-for="(item, blockIndex) in this.galleryBlocks"
          :key="`gallery-block-${blockIndex}`"
          :width="60"
          :header="item.headerText"
        >
          <template #panelContent>
            {{ item.text }}
            <lightgallery
              :settings="{ speed: 500, plugins: plugins }"
              :onInit="onLightGalleryInit"
              :onBeforeSlide="onBeforeSlide"
              :closable="true"
              class="lightgallery"
            >
              <a
                v-for="(n, imageIndex) in item.images.length"
                :key="`gallery-image-${blockIndex}-${imageIndex}`"
                :data-src="getGalleryImageUrl(item.images[imageIndex])"
                :data-sub-html="item.images[imageIndex].fields.description"
              >
                <img
                  class="loading thumbnail"
                  :src="getGalleryImageUrl(item.images[imageIndex])"
                />
              </a>
            </lightgallery>
          </template>
        </Panel>
      </template>
    </Page>
  </div>
</template>

<script lang="ts">
import "./gallery-page.scss";
import { Options, setup, Vue } from "vue-class-component";
import { useMeta } from "vue-meta";
import Page from "@/components/page/page.vue";
import Panel from "@/components/panel/panel.vue";
import Lightgallery from "lightgallery/vue";
import {
  getImageUrlFromContentfulEntity,
  getGalleryBlockFromContentfulEntity,
} from "@/store/utils";
import { GalleryBlock } from "@/store/common.types";
import { FETCH_GALLERY_PAGE } from "@/store/modules/gallery-page/gallery-page.actions";
import { GALLERY_PAGE_ENTRY_ID } from "@/store/api.config";
import { GalleryPageEntity } from "@/store/modules/gallery-page/gallery-page.model";
import { ContentfulEntity } from "@/store/contentful.types";

@Options({
  name: "GalleryPage",
  components: {
    Page,
    Panel,
    Lightgallery,
  },
  data: () => ({
    plugins: [],
  }),
  methods: {
    onLightGalleryInit: () => {
      // console.log("lightGallery: onLightGalleryInit");
    },
    onBeforeSlide: () => {
      // console.log("lightGallery: onBeforeSlide");
    },
  },
})
export default class GalleryPage extends Vue {
  //#region Meta
  meta = setup(() =>
    useMeta({
      title: "Our Work",
      htmlAttrs: { lang: "en", amp: true },
    })
  );
  //#endregion

  //#region Component
  isLoading = true;
  galleryPageEntity: GalleryPageEntity | null = null;
  galleryBlocks: Array<GalleryBlock> | null = null;
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    await this.$store.dispatch(FETCH_GALLERY_PAGE, GALLERY_PAGE_ENTRY_ID);
    this.galleryPageEntity = this.$store.getters.galleryPageEntity;

    this.galleryBlocks = [];
    this.galleryPageEntity.galleryPageGalleryBlocks.forEach(
      async (entityBlock) => {
        await getGalleryBlockFromContentfulEntity(entityBlock).then(
          (galleryBlock) => this.galleryBlocks.push(galleryBlock)
        );
      }
    );

    this.isLoading = false;
  }
  //#endregion

  //#region Functions
  getGalleryImageUrl(contentfulEntity: ContentfulEntity): string {
    return getImageUrlFromContentfulEntity(contentfulEntity);
  }
  //#endregion
}
GalleryPage.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave",
]);
</script>

<style lang="css">
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css");
</style>
